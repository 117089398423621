 <template>
  <v-dialog v-model="showTimingTemplate" width="60%" persistent>
    <v-form ref="form">
      <v-card class="">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Timing Templates" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-card
            class="pa-2 mt-8 bordered shadow-0"
            v-for="(timings, k) in timingTemplates"
            :key="k"
            height="100px"
            elevation="0"
          >
            <v-row>
              <v-col md="4">
                <label for="">Name*</label>
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                  v-model="timings.name"
                  outlined
                  background-color="#fff"
                  class="q-text-field shadow-0"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col lg="4">
                <label for="">Choose Days Applicable</label>
                <v-autocomplete
                  :items="weekdays"
                  item-text="name"
                  item-value="bit_value"
                  multiple
                  outlined
                  background-color="#fff"
                  :rules="[(v) => v.length > 0 || 'Weekdays is required']"
                  v-model="timings.weekdays"
                  validate-on-blur
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details
                >
                  <template
                    v-if="weekdays.length == timings.weekdays.length"
                    v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Days</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, {{ item.name }}</span>
                    <span v-if="index == 2">, ...</span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle(k)">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            timings.weekdays.length > 0 ? 'indigo darken-4' : ''
                          "
                          >{{ getIcon(k) }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col lg="2">
                <label for="">From*</label>
                <v-select
                  :items="timingData.slice(0, (timingData.length -1))"
                  item-text="text"
                  item-value="value"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'From time is required']"
                  v-model="timings.start_time"
                  required
                  outlined
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col lg="2">
                <label for="">To*</label>

                <v-select
                  :items="timingData.slice(1)"
                  item-text="text"
                  item-value="value"
                  v-model="timings.end_time"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'To time is required']"
                  required
                  outlined
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-tooltip bottom v-if="timingTemplates.length > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    @click="deleteTimingTemplate(k)"
                    fab
                    x-small
                    absolute
                    top
                    right
                    dark
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-row>

          </v-card>
          <v-row dense class="mt-4">
            <v-col>
              <p class="text-base text-neon text-underline font-bold pointer"  @click="
                    timingTemplates.push({
                      name: null,
                      weekdays: [],
                      end_time: null,
                    })
                  ">+Add Timing Template</p>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="ma-2 " text @click="close()"
            >Close</v-btn>
          <v-btn
            class="ma-2 white--text blue-color"
            @click="addNewTimingTemplate()"
            text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {SvgIcon},
  props: ["show-timing-template", "venue-service-id"],

  data() {
    return {
      timingTemplates: [
        {
          name: null,
          weekdays: [],
          end_time: null,
          start_time: null,
        },
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      deleted_timing_templates: [],
      timingData: [],
      timeIncrement: 60,
    };
  },

  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  watch: {
    showTimingTemplate: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTimingTemplates();
        }
      },
    },
    venueServiceId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initializeFacilityServices();
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
  },
  methods: {
    deleteTimingTemplate(index) {
      if (this.timingTemplates[index].id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this templates?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete",
        };
      } else {
        this.timingTemplates.splice(index, 1);
      }
    },

    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              let data = this.$store.getters.getConfigurationByVenueServiceId(
                this.venueServiceId
              );
              this.timeIncrement =
                data.time_increment != null ? data.time_increment : 60;
              this.generateTiming();
            }
          });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
        );
        this.timeIncrement =
          data.time_increment != null ? data.time_increment : 60;
        this.generateTiming();
      }
    },

    confirmActions(data) {
      this.deleted_timing_templates.push(this.timingTemplates[data.id].id);
      this.timingTemplates.splice(data.id, 1);
    },

    getTimingTemplates() {
      this.showLoader("Loading");
      this.$store
        .dispatch("loadTimingTemplatesByVenueServiceId", this.venueServiceId)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.timingTemplates = JSON.parse(
              JSON.stringify(response.data.data)
            );
            if (this.timingTemplates.length == 0) {
              this.timingTemplates = [
                {
                  name: null,
                  weekdays: [],
                  end_time: null,
                  start_time: null,
                },
              ];
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    close() {
      this.$emit("close", true);
    },
    addNewTimingTemplate() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      this.timingTemplates.forEach((template, index) => {
        for (let key in template) {
          formData.append(
            `timing_templates[${index}][${key}]`,
            typeof template[key] != "object"
              ? template[key]
              : JSON.stringify(template[key])
          );
        }
        formData.set(
          `timing_templates[${index}][venue_service_id]`,
          this.venueServiceId
        );
      });

      if (this.deleted_timing_templates.length) {
        this.deleted_timing_templates.forEach((item, index) => {
          formData.append(`deleted_timing_templates[${index}]`, item);
        });
      }
      this.showLoader();
      this.$http
        .post("venues/facilities/timing-templates", formData)
        .then((response) => {
          if (response.status == 200) {
            this.$store.dispatch(
              "loadTimingTemplatesByVenueServiceId",
              this.venueServiceId
            );
            this.hideLoader();
            this.showSuccess("Timing template updated successfully");
            this.close();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getIcon(index) {
      let icon = "mdi-checkbox-blank-outline";
      if (this.timingTemplates[index].weekdays.length == this.weekdays.length) {
        icon = "mdi-close-box";
      }
      if (
        this.timingTemplates[index].weekdays.length > 0 &&
        this.timingTemplates[index].weekdays.length != this.weekdays.length
      )
        icon = "mdi-minus-box";
      return icon;
    },

    toggle(index) {
      this.$nextTick(() => {
        if (
          this.timingTemplates[index].weekdays.length == this.weekdays.length
        ) {
          this.timingTemplates[index].weekdays = [];
        } else {
          this.timingTemplates[index].weekdays = this.weekdays.map((item) => {
            return item.bit_value;
          });
        }
      });
      this.$forceUpdate();
    },

    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timingData = [];
      this.timingData.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timingData.push(data);
          hour = 24;
        } else {
          this.timingData.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
  },
};
</script>
