var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","persistent":""},model:{value:(_vm.showTimingTemplate),callback:function ($$v) {_vm.showTimingTemplate=$$v},expression:"showTimingTemplate"}},[_c('v-form',{ref:"form"},[_c('v-card',{},[_c('v-card-text',{staticClass:"border-bottom"},[_c('div',{staticClass:"row pt-1 border-bottom"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('SvgIcon',{staticClass:"text-2xl font-semibold",staticStyle:{"color":"black"},attrs:{"text":"Timing Templates"}}),_c('v-btn',{staticClass:"shadow-0",attrs:{"fab":"","x-small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])]),_vm._l((_vm.timingTemplates),function(timings,k){return _c('v-card',{key:k,staticClass:"pa-2 mt-8 bordered shadow-0",attrs:{"height":"100px","elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Name*")]),_c('v-text-field',{staticClass:"q-text-field shadow-0",attrs:{"required":"","rules":[function (v) { return !!v || 'Name is required'; }],"outlined":"","background-color":"#fff","dense":"","hide-details":""},model:{value:(timings.name),callback:function ($$v) {_vm.$set(timings, "name", $$v)},expression:"timings.name"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Choose Days Applicable")]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.weekdays,"item-text":"name","item-value":"bit_value","multiple":"","outlined":"","background-color":"#fff","rules":[function (v) { return v.length > 0 || 'Weekdays is required'; }],"validate-on-blur":"","dense":"","hide-details":""},scopedSlots:_vm._u([(_vm.weekdays.length == timings.weekdays.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v("All Days")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(", "+_vm._s(item.name))]):_vm._e(),(index == 2)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle(k)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":timings.weekdays.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon(k)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(timings.weekdays),callback:function ($$v) {_vm.$set(timings, "weekdays", $$v)},expression:"timings.weekdays"}})],1),_c('v-col',{attrs:{"lg":"2"}},[_c('label',{attrs:{"for":""}},[_vm._v("From*")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.timingData.slice(0, (_vm.timingData.length -1)),"item-text":"text","item-value":"value","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'From time is required'; }],"required":"","outlined":"","background-color":"#fff","dense":"","hide-details":""},model:{value:(timings.start_time),callback:function ($$v) {_vm.$set(timings, "start_time", $$v)},expression:"timings.start_time"}})],1),_c('v-col',{attrs:{"lg":"2"}},[_c('label',{attrs:{"for":""}},[_vm._v("To*")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.timingData.slice(1),"item-text":"text","item-value":"value","menu-props":{ bottom: true, offsetY: true },"rules":[function (v) { return !!v || 'To time is required'; }],"required":"","outlined":"","background-color":"#fff","dense":"","hide-details":""},model:{value:(timings.end_time),callback:function ($$v) {_vm.$set(timings, "end_time", $$v)},expression:"timings.end_time"}})],1),(_vm.timingTemplates.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","fab":"","x-small":"","absolute":"","top":"","right":"","dark":""},on:{"click":function($event){return _vm.deleteTimingTemplate(k)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete ")]):_vm._e()],1)],1)}),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',[_c('p',{staticClass:"text-base text-neon text-underline font-bold pointer",on:{"click":function($event){return _vm.timingTemplates.push({
                    name: null,
                    weekdays: [],
                    end_time: null,
                  })}}},[_vm._v("+Add Timing Template")])])],1)],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 ",attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"text":""},on:{"click":function($event){return _vm.addNewTimingTemplate()}}},[_vm._v("Update")])],1)],1)],1),_c('confirm-model',_vm._b({on:{"confirm":_vm.confirmActions,"close":function($event){_vm.confirmModel.id = null}}},'confirm-model',_vm.confirmModel,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }